<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="streamMediaWrap preview">
    <i class="iconfont icon-streamMedia"></i>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.streamMediaWrap {
  width: 100%;
  height: 100%;
  user-select: none;
  display: table;
  i {
    font-size: inherit;
  }
}
</style>
